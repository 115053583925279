

// returns the index of the object with matching property
export default function find( list, field, val )
{
	for ( let i = 0; i < list.length; i++ )
	{
		if ( list[i][field] == val )
			return i
	}

	return -1
}
