import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from '@nuxt/ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _69ad5ff0 = () => interopDefault(import('../resources/pages/academy/index.vue' /* webpackChunkName: "pages/academy/index" */))
const _d0a0612c = () => interopDefault(import('../resources/pages/login.vue' /* webpackChunkName: "pages/login" */))
const _f529f548 = () => interopDefault(import('../resources/pages/academy/edit.vue' /* webpackChunkName: "pages/academy/edit" */))
const _dbab7604 = () => interopDefault(import('../resources/pages/academy/ekpaideytika-programmata/index.vue' /* webpackChunkName: "pages/academy/ekpaideytika-programmata/index" */))
const _28001346 = () => interopDefault(import('../resources/pages/academy/prwtoporiakh-methodos-ekpaideyshs/index.vue' /* webpackChunkName: "pages/academy/prwtoporiakh-methodos-ekpaideyshs/index" */))
const _15418b28 = () => interopDefault(import('../resources/pages/academy/syxnes-erwthseis/index.vue' /* webpackChunkName: "pages/academy/syxnes-erwthseis/index" */))
const _32751494 = () => interopDefault(import('../resources/pages/academy/voithimata-metafrashs/index.vue' /* webpackChunkName: "pages/academy/voithimata-metafrashs/index" */))
const _0b5372e4 = () => interopDefault(import('../resources/pages/academy/ekpaideytika-programmata/edit.vue' /* webpackChunkName: "pages/academy/ekpaideytika-programmata/edit" */))
const _122ce174 = () => interopDefault(import('../resources/pages/academy/prwtoporiakh-methodos-ekpaideyshs/edit.vue' /* webpackChunkName: "pages/academy/prwtoporiakh-methodos-ekpaideyshs/edit" */))
const _2f15eb60 = () => interopDefault(import('../resources/pages/academy/syxnes-erwthseis/edit.vue' /* webpackChunkName: "pages/academy/syxnes-erwthseis/edit" */))
const _dc93c254 = () => interopDefault(import('../resources/pages/academy/voithimata-metafrashs/edit.vue' /* webpackChunkName: "pages/academy/voithimata-metafrashs/edit" */))
const _756809b9 = () => interopDefault(import('../resources/pages/academy/ekpaideytika-programmata/_slug/index.vue' /* webpackChunkName: "pages/academy/ekpaideytika-programmata/_slug/index" */))
const _1d9d0d42 = () => interopDefault(import('../resources/pages/academy/page/_slug/index.vue' /* webpackChunkName: "pages/academy/page/_slug/index" */))
const _543f7e73 = () => interopDefault(import('../resources/pages/academy/ekpaideytika-programmata/_slug/edit.vue' /* webpackChunkName: "pages/academy/ekpaideytika-programmata/_slug/edit" */))
const _8952fbe6 = () => interopDefault(import('../resources/pages/academy/page/_slug/edit.vue' /* webpackChunkName: "pages/academy/page/_slug/edit" */))
const _0618aacc = () => interopDefault(import('../resources/pages/main-domain/_lang/index.vue' /* webpackChunkName: "pages/main-domain/_lang/index" */))
const _6788851c = () => interopDefault(import('../resources/pages/main-domain/_lang/edit.vue' /* webpackChunkName: "pages/main-domain/_lang/edit" */))
const _3f10afe9 = () => interopDefault(import('../resources/pages/main-domain/_lang/ekseidikeysh/index.vue' /* webpackChunkName: "pages/main-domain/_lang/ekseidikeysh/index" */))
const _0b5c6fc4 = () => interopDefault(import('../resources/pages/main-domain/_lang/etairia/index.vue' /* webpackChunkName: "pages/main-domain/_lang/etairia/index" */))
const _40a30eb2 = () => interopDefault(import('../resources/pages/main-domain/_lang/kariera/index.vue' /* webpackChunkName: "pages/main-domain/_lang/kariera/index" */))
const _4b165902 = () => interopDefault(import('../resources/pages/main-domain/_lang/syxnes-erwthseis/index.vue' /* webpackChunkName: "pages/main-domain/_lang/syxnes-erwthseis/index" */))
const _c048721a = () => interopDefault(import('../resources/pages/main-domain/_lang/yphresies/index.vue' /* webpackChunkName: "pages/main-domain/_lang/yphresies/index" */))
const _c65d5b7a = () => interopDefault(import('../resources/pages/main-domain/_lang/ekseidikeysh/edit.vue' /* webpackChunkName: "pages/main-domain/_lang/ekseidikeysh/edit" */))
const _88bc4124 = () => interopDefault(import('../resources/pages/main-domain/_lang/etairia/edit.vue' /* webpackChunkName: "pages/main-domain/_lang/etairia/edit" */))
const _3183765a = () => interopDefault(import('../resources/pages/main-domain/_lang/kariera/edit.vue' /* webpackChunkName: "pages/main-domain/_lang/kariera/edit" */))
const _2155a20a = () => interopDefault(import('../resources/pages/main-domain/_lang/syxnes-erwthseis/edit.vue' /* webpackChunkName: "pages/main-domain/_lang/syxnes-erwthseis/edit" */))
const _662231f9 = () => interopDefault(import('../resources/pages/main-domain/_lang/yphresies/edit.vue' /* webpackChunkName: "pages/main-domain/_lang/yphresies/edit" */))
const _41af5864 = () => interopDefault(import('../resources/pages/main-domain/_lang/ekseidikeysh/_slug/index.vue' /* webpackChunkName: "pages/main-domain/_lang/ekseidikeysh/_slug/index" */))
const _5e8b7fee = () => interopDefault(import('../resources/pages/main-domain/_lang/yphresies/_slug/index.vue' /* webpackChunkName: "pages/main-domain/_lang/yphresies/_slug/index" */))
const _39ce2e68 = () => interopDefault(import('../resources/pages/main-domain/_lang/ekseidikeysh/_slug/edit.vue' /* webpackChunkName: "pages/main-domain/_lang/ekseidikeysh/_slug/edit" */))
const _1f2c24c4 = () => interopDefault(import('../resources/pages/main-domain/_lang/yphresies/_slug/edit.vue' /* webpackChunkName: "pages/main-domain/_lang/yphresies/_slug/edit" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/academy",
    component: _69ad5ff0,
    pathToRegexpOptions: {"strict":true},
    name: "academy"
  }, {
    path: "/login",
    component: _d0a0612c,
    pathToRegexpOptions: {"strict":true},
    name: "login"
  }, {
    path: "/academy/edit",
    component: _f529f548,
    pathToRegexpOptions: {"strict":true},
    name: "academy-edit"
  }, {
    path: "/academy/ekpaideytika-programmata",
    component: _dbab7604,
    pathToRegexpOptions: {"strict":true},
    name: "academy-ekpaideytika-programmata"
  }, {
    path: "/academy/prwtoporiakh-methodos-ekpaideyshs",
    component: _28001346,
    pathToRegexpOptions: {"strict":true},
    name: "academy-prwtoporiakh-methodos-ekpaideyshs"
  }, {
    path: "/academy/syxnes-erwthseis",
    component: _15418b28,
    pathToRegexpOptions: {"strict":true},
    name: "academy-syxnes-erwthseis"
  }, {
    path: "/academy/voithimata-metafrashs",
    component: _32751494,
    pathToRegexpOptions: {"strict":true},
    name: "academy-voithimata-metafrashs"
  }, {
    path: "/academy/ekpaideytika-programmata/edit",
    component: _0b5372e4,
    pathToRegexpOptions: {"strict":true},
    name: "academy-ekpaideytika-programmata-edit"
  }, {
    path: "/academy/prwtoporiakh-methodos-ekpaideyshs/edit",
    component: _122ce174,
    pathToRegexpOptions: {"strict":true},
    name: "academy-prwtoporiakh-methodos-ekpaideyshs-edit"
  }, {
    path: "/academy/syxnes-erwthseis/edit",
    component: _2f15eb60,
    pathToRegexpOptions: {"strict":true},
    name: "academy-syxnes-erwthseis-edit"
  }, {
    path: "/academy/voithimata-metafrashs/edit",
    component: _dc93c254,
    pathToRegexpOptions: {"strict":true},
    name: "academy-voithimata-metafrashs-edit"
  }, {
    path: "/academy/ekpaideytika-programmata/:slug",
    component: _756809b9,
    pathToRegexpOptions: {"strict":true},
    name: "academy-ekpaideytika-programmata-slug"
  }, {
    path: "/academy/page/:slug",
    component: _1d9d0d42,
    pathToRegexpOptions: {"strict":true},
    name: "academy-page-slug"
  }, {
    path: "/academy/ekpaideytika-programmata/:slug?/edit",
    component: _543f7e73,
    pathToRegexpOptions: {"strict":true},
    name: "academy-ekpaideytika-programmata-slug-edit"
  }, {
    path: "/academy/page/:slug?/edit",
    component: _8952fbe6,
    pathToRegexpOptions: {"strict":true},
    name: "academy-page-slug-edit"
  }, {
    path: "/main-domain/:lang",
    component: _0618aacc,
    pathToRegexpOptions: {"strict":true},
    name: "main-domain-lang"
  }, {
    path: "/main-domain/:lang?/edit",
    component: _6788851c,
    pathToRegexpOptions: {"strict":true},
    name: "main-domain-lang-edit"
  }, {
    path: "/main-domain/:lang?/ekseidikeysh",
    component: _3f10afe9,
    pathToRegexpOptions: {"strict":true},
    name: "main-domain-lang-ekseidikeysh"
  }, {
    path: "/main-domain/:lang?/etairia",
    component: _0b5c6fc4,
    pathToRegexpOptions: {"strict":true},
    name: "main-domain-lang-etairia"
  }, {
    path: "/main-domain/:lang?/kariera",
    component: _40a30eb2,
    pathToRegexpOptions: {"strict":true},
    name: "main-domain-lang-kariera"
  }, {
    path: "/main-domain/:lang?/syxnes-erwthseis",
    component: _4b165902,
    pathToRegexpOptions: {"strict":true},
    name: "main-domain-lang-syxnes-erwthseis"
  }, {
    path: "/main-domain/:lang?/yphresies",
    component: _c048721a,
    pathToRegexpOptions: {"strict":true},
    name: "main-domain-lang-yphresies"
  }, {
    path: "/main-domain/:lang?/ekseidikeysh/edit",
    component: _c65d5b7a,
    pathToRegexpOptions: {"strict":true},
    name: "main-domain-lang-ekseidikeysh-edit"
  }, {
    path: "/main-domain/:lang?/etairia/edit",
    component: _88bc4124,
    pathToRegexpOptions: {"strict":true},
    name: "main-domain-lang-etairia-edit"
  }, {
    path: "/main-domain/:lang?/kariera/edit",
    component: _3183765a,
    pathToRegexpOptions: {"strict":true},
    name: "main-domain-lang-kariera-edit"
  }, {
    path: "/main-domain/:lang?/syxnes-erwthseis/edit",
    component: _2155a20a,
    pathToRegexpOptions: {"strict":true},
    name: "main-domain-lang-syxnes-erwthseis-edit"
  }, {
    path: "/main-domain/:lang?/yphresies/edit",
    component: _662231f9,
    pathToRegexpOptions: {"strict":true},
    name: "main-domain-lang-yphresies-edit"
  }, {
    path: "/main-domain/:lang?/ekseidikeysh/:slug",
    component: _41af5864,
    pathToRegexpOptions: {"strict":true},
    name: "main-domain-lang-ekseidikeysh-slug"
  }, {
    path: "/main-domain/:lang?/yphresies/:slug",
    component: _5e8b7fee,
    pathToRegexpOptions: {"strict":true},
    name: "main-domain-lang-yphresies-slug"
  }, {
    path: "/main-domain/:lang?/ekseidikeysh/:slug/edit",
    component: _39ce2e68,
    pathToRegexpOptions: {"strict":true},
    name: "main-domain-lang-ekseidikeysh-slug-edit"
  }, {
    path: "/main-domain/:lang?/yphresies/:slug/edit",
    component: _1f2c24c4,
    pathToRegexpOptions: {"strict":true},
    name: "main-domain-lang-yphresies-slug-edit"
  }],

  fallback: false
}

function decodeObj(obj) {
  for (const key in obj) {
    if (typeof obj[key] === 'string') {
      obj[key] = decode(obj[key])
    }
  }
}

export function createRouter () {
  const router = new Router(routerOptions)

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    const r = resolve(to, current, append)
    if (r && r.resolved && r.resolved.query) {
      decodeObj(r.resolved.query)
    }
    return r
  }

  return router
}
